/* colours */
$c-bg: #F3F3FA;
$c-text: #334155;
$c-text-light: #e2e8f0;
$c-border: #f1f5f9;
$c-border-dark: #cbd5e1;
$c-primary: #73B0F4;
$c-dark: #1e293b;
$c-brand: #6363ac;
$c-slate-100: #f1f5f9;
$c-slate-200: #e2e8f0;
$c-slate-300: #cbd5e1;
$c-slate-400: #94a3b8;
$c-slate-500: #64748b;
$c-slate-600: #475569;
$c-slate-700: #334155;
$c-slate-800: #1e293b;
$c-slate-900: #0f172a;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #D73A17;

$c-red: #ef4444;
$c-green: #10b981;
$c-blue: #3b82f6;
$c-darkblue: #1e3a8a;
$c-orange: #f97316;
$c-tint: #e2e8f0;
$c-purple: #8b5cf6;
$c-darkpurple: #3730a3;
