@import 'components/global.scss';

.subnav {

  position: relative;
  padding: $scale1 $scale4 0;
  margin: $scale2*-1 $scale2*-1 $scale2 $scale1*-2;
  background: white;
  border-top: 1px solid $c-border;

}

.item {

  color: $c-text;
  display: inline-block;
  padding-bottom: $scale;
  margin-right: $scale2;
  text-decoration: none;

  &.active {

    // border-bottom: 2px solid $c-blue;
    border-bottom: 2px solid #E01A96;
  
  }
}

.dark {

  .subnav {

    background: $c-slate-800;
    border: none;

  }

  .item {

    color: $c-text-light;

  }

  .item_active {

    color: $c-text-light;

  }
}

:global(.dark){

  .subnav {

    background: $c-slate-800;
    border: none;

  }

  .item {

    color: $c-slate-200;

  }

  .item_active {

    color: $c-slate-200;

  }
}

